import React from "react";

import Layout from "../containers/layout";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import PageContainer from "../components/pageContainer";
import PricelistMain from "../components/pricelist";

const Pricelist = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const magazinPreview = data && data?.magazinPreview?.nodes;
  const allServices = data && data?.allServices?.nodes;
  const pricelistData = data && data?.pricelist?.nodes;
  const tags = data && data?.tags?.nodes;

  return (
    <Layout services={allServices} tags={tags} magazin={magazinPreview}>
      <SEO
        title={"Ceník"}
        description={"Přehledný ceník všech procedur a služeb poskytovaných na Beauty Body Clinic"}
        keywords={["Cena", "Ceník", "Ceny"]}
      />
      <PageContainer>
        <PricelistMain pricelistData={pricelistData} />
      </PageContainer>
    </Layout>
  );
};

export default Pricelist;
