import React from "react";
import { graphql } from "gatsby";
import Errors from "../components/errors";
import Pricelist from "../templates/pricelist";

export const query = graphql`
  query PricelistQuery {
    allServices: allSanityService(sort: { fields: title }) {
      nodes {
        title
        disabled
        slug {
          current
        }
      }
    }
    magazinPreview: allSanityPost(sort: { fields: publishedAt, order: DESC }, limit: 6) {
      nodes {
        title
        slug {
          current
        }
      }
    }
    tags: allSanityCategory(sort: { fields: title }) {
      nodes {
        title
        _id
      }
    }
    pricelist: allSanityService(sort: { fields: title }) {
      nodes {
        title
        disabled
        pricelist {
          price
          title
          disabled
        }
        procedures {
          procedure {
            title
            price
            pricelist {
              price
              title
              disabled
            }
          }
        }
      }
    }
  }
`;

const PricelistPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return <Errors errors={errors} />;
  }

  return <Pricelist data={data} />;
};

export default PricelistPage;
