import React from "react";

import { cn } from "../lib/helpers";

import * as styles from "./procedure.module.css";
import * as commonStyles from "../styles/common.module.css";

const Procedure = ({ procedures, indexS }) => {
  return procedures.map(({ procedure }, indexP) => {
    if (procedure.pricelist.length === 0 && procedure.price !== null) {
      return (
        <li
          key={`procedure_${indexS}_${indexP}`}
          className={cn(styles.procedure, styles.pricelistItem)}
        >
          <h3
            className={cn(
              styles.procedureTitle,
              commonStyles.colorAccent,
              commonStyles.fontReleway
            )}
          >
            {procedure.title}
          </h3>
          <div
            className={cn(
              styles.price,
              commonStyles.colorDarkGray,
              commonStyles.fontReleway
            )}
          >
            {procedure.price}
          </div>
        </li>
      );
    }
    if (procedure.pricelist.length > 0) {
      return (
        <li key={`procedure_${indexS}_${indexP}`} className={styles.procedure}>
          <h3
            className={cn(
              styles.procedureTitle,
              commonStyles.colorAccent,
              commonStyles.fontReleway
            )}
          >
            {procedure.title}
          </h3>
          <ul className={cn(commonStyles.colorDarkGray, commonStyles.fontReleway)}>
            {procedure.pricelist.map((servicePrice, indexPP) => (
              <li
                key={`procedurePrice_${indexS}_${indexP}_${indexPP}`}
                className={cn(
                  styles.pricelistItem,
                  commonStyles.fontReleway,
                  commonStyles.colorDarkGray
                )}
              >
                <div>{`— ${servicePrice.title}`}</div>
                <div className={styles.price}>{servicePrice.price}</div>
              </li>
            ))}
          </ul>
        </li>
      );
    }
    return <li key={`procedure_${indexS}_${indexP}`}></li>;
  })
};

export default Procedure;
