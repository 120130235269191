import React from "react";
import { cn } from "../lib/helpers";

import Procedure from "./procedure";

import * as styles from "./pricelist.module.css";
import * as commonStyles from "../styles/common.module.css";

const isProceduresWithPrice = (procedures = []) =>
  procedures.some((procedure) => procedure.price !== null || procedure.pricelist.length !== 0);

class Pricelist extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { pricelistData } = this.props;

    return (
      <div className={styles.container}>
        <h1
          className={cn(styles.headline, commonStyles.colorAccent, commonStyles.fontButlerMedium)}
        >
          Ceník
        </h1>
        {pricelistData.map((service, indexS) => {
          if (
            (service.pricelist.length === 0 && !isProceduresWithPrice(service.procedures)) ||
            service.disabled
          ) {
            return <div key={`service_${indexS}`}></div>;
          }

          return (
            <div key={`service_${indexS}`} className={styles.service}>
              <h2
                className={cn(
                  styles.title,
                  commonStyles.colorAccent,
                  commonStyles.fontButlerMedium
                )}
              >
                {service.title}
              </h2>
              <ul key="list1" className={styles.pricelist}>
                {service.pricelist.map((servicePrice, indexSP) => (
                  <li
                    key={`servicePrice_${indexS}_${indexSP}`}
                    className={cn(
                      styles.procedure,
                      styles.pricelistItem,
                      commonStyles.fontReleway,
                      commonStyles.colorDarkGray
                    )}
                  >
                    <div
                      className={cn(
                        styles.procedureTitle,
                        commonStyles.colorAccent,
                        commonStyles.fontReleway
                      )}
                    >
                      {servicePrice.title}
                    </div>
                    <div className={styles.price}>{servicePrice.price}</div>
                  </li>
                ))}
              </ul>
              <ul key="list2">{<Procedure procedures={service.procedures} />}</ul>
            </div>
          );
        })}
      </div>
    );
  }
}

export default Pricelist;
